import { AfterViewInit, Component, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import { LocalStorageList } from '@src/app/helpers/enums/enums.keys';
import { AuthenticationService } from '@src/app/services/Security/authentication.service';
import { PersistenceService } from '@src/app/services/Utilities/data.persistence.service';
import { SettingsService } from '@src/app/services/Utilities/settings.service';
import { ThemeService } from '@src/app/services/Utilities/theme.service';
declare var clarity;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  nombreInstitucion = "";
  sandbox = 0;
  baseUrl: string;
  isLogin = false;

  name = "";
  iniciales = "";
  usuario = "";
  perfiles: any;;

  public popoverUserIsShow = false;
  public popoverHelpIsShow = false;

  public toolbarAyudar: Array<any> = [
    {
      text: 'Soporte técnico',
      icon: 'fa-solid fa-headset'
    },
    {
      text: 'Documentación',
      icon: 'fa-solid fa-book'
    }];

  @Input() drawer: DrawerComponent;
  @Input() paymentId: number;

  
  themeSwitcher = false;
  opened = false
  constructor(
    private persistenceService: PersistenceService,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService, 
    private themeService: ThemeService
  ) {

    this.themeSwitcher = themeService.isDarkModeMode();
 

    this.baseUrl = this.settingsService.getBaseUrlApi;

    this.isLogin = authenticationService.IsLogin();
    if (this.isLogin) {
      this.name = authenticationService.getName();
      this.iniciales = authenticationService.getIniciales();
      this.usuario = authenticationService.getUsuario();
      this.perfiles = authenticationService.getPerfiles();

      let userId = authenticationService.getUserId();

      clarity("set", "idUsuario", userId);

    }
  }

  ngOnInit() {
 
    if(this.baseUrl.includes("api.saludplus.co")){
      this.sandbox = 0;
    }

    if(this.baseUrl.includes("api-preprod.saludplus.co")){
      this.sandbox = 1;
    }

    if(this.baseUrl.includes("api-pruebas.saludplus.co")){
      this.sandbox = 2;
    }
 
    this.nombreInstitucion = this.persistenceService.getData(
      LocalStorageList.nombre_institucion
    );

  }
  ngAfterViewInit(): void {
    this.themeService.apply();
  }
  public close(status: string): void { 
    this.opened = false;
  }

  public onChangeSwitch(): void {
    if (this.themeSwitcher) {
      this.themeService.setLightMode();
    } else {
      this.themeService.setDarkMode();
    }
    this.themeSwitcher = !this.themeSwitcher;
  }

 

 

  /**
   * Cerrar sesion
   */
  logout() {
    this.authenticationService.logout();
    this.isLogin = false;
  }

  /**
   * Abrir documentacion
   */
  OpenDocumentacion() {
    this.authenticationService.OpenDocumentation();
  }

  onEvent(event, anchorUser) {

    anchorUser.hide()
    event.stopPropagation();
  }


}


