import { Component, ViewChild, OnInit, TemplateRef, AfterViewInit, Renderer2, HostListener, HostBinding, inject, effect } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription, timer } from "rxjs";

import { LRecursosExt } from "./models/SaludPlus/LRecursosExt.model";
import { LCaracteristicas } from "./models/SaludPlus/LCaracteristicas.model";

import { AuthenticationService } from "./services/Security/authentication.service";
import { PersistenceService } from "./services/Utilities/data.persistence.service";
import { LocalStorageList } from "./helpers/enums/enums.keys";

import { environment } from "@src/environments/environment";


import { SettingsService } from "./services/Utilities/settings.service";
import { Favorites, SPlusDialogSettings, SPlusFormsDialogsService, SPlusNotificationService, SPlusNotificationSettings, SPlusProcessMessageComponent } from "@saludplus/forms";
import { FilterExpandSettings } from "@progress/kendo-angular-treeview";
import { SharedService } from "./services/Generals/shared-service.service";
import { PopoverAnchorDirective } from "@progress/kendo-angular-tooltip";
import { SplusLogger } from "./services/Utilities/splus.logger.service";

declare var gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {

  public expanded = true;
  public expandedKeys: any[] = ['-1'];


  isLogin = false;
  paymentId = 0;

  menu: LRecursosExt[] = [];
  caracteristicas: LCaracteristicas[] = [];
  showMenu = false;
  sub: Subscription;

  opened = true;
  permissionError = false;

  showMessageProcess: boolean;
  messageProcess = new SPlusProcessMessageComponent();
  version: string;
  ShowUpdateMjs: boolean = false;
  currentModule = [];

  modulesList: Array<any>;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  myTimerSub: Subscription;

  @ViewChild('templateOffline', { static: false }) public templateOffline: TemplateRef<any>;
  @ViewChild('templateOnline', { static: false }) public templateOnline: TemplateRef<any>;
  baseUrl: string;

  public favorites: Favorites[] = [];
  FavoritoSelect: Favorites;
  menuOver: any;

  doc;
  windowScrolled: boolean;
  @ViewChild('internalmyPopoverMenu', { static: true }) internalmyPopoverMenu: PopoverAnchorDirective;


  //private splusFormsDialogsService = inject(SPlusFormsDialogsService);
  private notificationService = inject(SPlusNotificationService);
  private sharedService = inject(SharedService);
  private logger = inject(SplusLogger);
  private authenticationService = inject(AuthenticationService);
  private settingsService = inject(SettingsService);
  private router = inject(Router);
  private persistenceService = inject(PersistenceService);
  private renderer = inject(Renderer2);

  constructor() {

    this.baseUrl = this.settingsService.getBaseUrlApi;
    this.isLogin = this.authenticationService.IsLogin();
    this.paymentId = this.authenticationService.getPaymentId();

    this.serverStatus();
    this.checkSession();

    if (environment.production) {
      //console.info("Google Analytics Activado!!")
      const navEndEvents$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
      navEndEvents$.subscribe((event: NavigationEnd) => {
        gtag('config', 'G-581YHK4S33', {
          'page_path': event.urlAfterRedirects
        });
      });
    }

    effect(() => {

      this._subscribeNotifications();
    })
  }
  screenWidth: number;

  ngAfterViewInit(): void {

 

    this.doc = document.getElementsByClassName("k-drawer-content")[0];
    this.renderer.listen(this.doc, 'scroll', (event) => {
      // Do something with 'event'
      this.onWindowScroll()


    });

    let me = this
    setTimeout(function () {


      if (me.persistenceService.hasKey(LocalStorageList.drawerExpanded)) {
        var status = me.persistenceService.getData(LocalStorageList.drawerExpanded) === "true";
        me.expanded = status
      } else {

        if (window.innerWidth >= 768) {
          me.expanded = true
        } else {
          me.expanded = false
        }

      }
    }, 10);

  }

  @HostListener("window:scroll", [])
  onWindowScroll() {

    const scrollTop = window.scrollY || this.doc.scrollTop || document.body.scrollTop; 
    if (!this.router.url.includes('developer')) { 
      this.windowScrolled = scrollTop > 42.72;
    }

  }

  public DrawerOnCollapse(status: boolean): void {
    this.persistenceService.setData(LocalStorageList.drawerExpanded, status);
  }
 
 
  ngOnInit() {



    if (!this.isLogin) {
      return;
    }
    this.sub = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url !== "/seleccionar-institucion") {
          this.getMenu();
          this.showMenu = true;
        } else {
          this.showMenu = false;
        }
      });

    try {
      this.currentModule = this.router.url.split("/");

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.currentModule = event.url.split("/");


          if (this.windowScrolled && (event.url.includes('mode=i') || event.url.includes('mode=u'))) {
            this.scrollToTop();
          }

          this.windowScrolled = false;
        }
      });
    } catch (error) { }


  }

  public filterExpandSettings: FilterExpandSettings = {
    maxAutoExpandResults: 10,
  };

  scrollToTop() {
    let me = this;
    (function smoothscroll() {

      var currentScroll = me.doc.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        try {
          window.requestAnimationFrame(smoothscroll);
          document.getElementsByClassName("k-drawer-content")[0].scrollTop = currentScroll - (currentScroll / 8);
        } catch (error) {
          me.logger.error(error)
        }

      }

    })();
  }

  public isItemSelected = (_: any, index: string) => this.expandedKeys.indexOf(index) > -1;

  showClick(dataItem, index, popMenu) {

    if (popMenu) {
      this.popMenu = popMenu;
    }

    //console.log(dataItem, this.isItemSelected(dataItem, index), index);
    let expanded = [];

    if (dataItem && (dataItem["idRecurso"] || dataItem["caracteristicas"])) {
      if (this.isItemSelected(dataItem, index)) {

        let expandedSplit = index.split("_", 3);
        if (expandedSplit.length > 1) {
          expanded.push(expandedSplit[0]);

          this.expandedKeys = expanded;
          //console.log(this.expandedKeys);
        } else {
          this.expandedKeys = [-1];
        }


      } else {

        let expandedSplit = index.split("_", 3);

        if (expandedSplit.length > 1) {
          expanded.push(expandedSplit[0]);
        }

        if (expanded.indexOf(index) == -1) {
          expanded.push(index);
        }

        //console.log(expanded);
        this.expandedKeys = expanded;
      }

    }
  }

  popMenu: any;
  OcultarMenu() {
    try {
      this.popMenu.hide()
    } catch {
    }

  }


  validateCurrentModule(itemMenu, index): boolean {
    try {
      if (!itemMenu || !this.currentModule) {
        return false;
      } else {
        if (index == 1 && itemMenu.toLowerCase() == "triage") {
          itemMenu = "urgencias";
        }

        return (
          itemMenu.toLowerCase() == this.currentModule[index].toLowerCase()
        );
      }
    } catch (error) {
      return false;
    }
  }

  async getMenu() {


    // Menu Principal
    const dataRecursos = (await this.authenticationService.ListadoRecursosPorInstitucion(
      true
    )) as LRecursosExt[];

    // Caracteristicas del menu principal
    this.caracteristicas = (await this.authenticationService.ListadoCaracteristicasPorUsuario(
      true
    )) as LCaracteristicas[];
    const validaSede =
      this.persistenceService.getData(LocalStorageList.aplicaSede) === "true";
    if (!validaSede) {
      this.caracteristicas = this.caracteristicas.filter(
        (s) => s.nombre !== "Sedes"
      );
    }


    //limpiar recursos repetidos
    let dataRecursosClear: LRecursosExt[] = [];
    dataRecursos.forEach(element => {
      if (!dataRecursosClear.some(s => s.idRecurso == element.idRecurso)) {
        dataRecursosClear.push(element)
      }
    });

    // Mapeo del menu con sus caracteristicas
    dataRecursosClear.forEach((elementRecurso) => {
      const caracteristicas = this.caracteristicas.filter(
        (s) => s.fkRecurso === elementRecurso.idRecurso
      );
      elementRecurso.caracteristicas = caracteristicas;
    });

    //validar favoritos
    this.asignarFavoritos();
    //console.log("dataRecursosClear", dataRecursosClear);
    this.menu = this.menu.concat(dataRecursosClear);


    //validamos el modulo actual
    this.SeleccionarMenuActual();

    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * seleccionar menu actual por el indice del menu
   */
  SeleccionarMenuActual() {
    let irMenu = [];
    let indexModulo = -1;
    let indexLink = -1;

    if (this.currentModule[1]) {
      //buscar en el menu el modulo actual
      let moduleText = this.currentModule[1];
      indexModulo = this.menu.findIndex(s => s.idRecurso && s.nombre.replaceAll(' ', '-').toLocaleLowerCase() == moduleText.toLocaleLowerCase());
      irMenu.push(indexModulo.toString());
    }

    if (indexModulo && this.currentModule[2]) {
      //buscar en el menu la caracteristica actual
      let caracteristicaText = this.currentModule[2];
      caracteristicaText = caracteristicaText == 'informes' ? 'informe' : caracteristicaText;
      //TODO llamar a isaac para cambiar el menu

      const indexLinkC = this.menu[indexModulo];


      if (indexLinkC) {
        indexLink = indexLinkC?.caracteristicas.findIndex(s => s.nombre.replaceAll(' ', '-').toLocaleLowerCase() == caracteristicaText.toLocaleLowerCase());

        irMenu.push(`${indexModulo}_${indexLink.toString()}`);
      }
    }


    if (indexModulo && this.currentModule[3]) {
      //buscar en el menu la caracteristica actual en e submenu
      let moduleText = this.currentModule[3];
      const indexC = this.menu[indexModulo]?.caracteristicas[indexLink];

      if (indexC && indexC["caracteristicas"]) {

        const index = indexC
          ?.caracteristicas
          .findIndex(s => s.nombre.replaceAll(' ', '-').toLocaleLowerCase() == moduleText.toLocaleLowerCase());

        irMenu.push(`${indexModulo}_${indexLink}_${index.toString()}`);

      }

    }

    //console.log("irMenu", this.menu);

    if (irMenu && irMenu.length > 0) {
      this.expandedKeys = irMenu;
    } else {
      //asignamos el primer item del menu como default
      irMenu = ['0'];
    }

  }


  logout() {
    this.authenticationService.logout();
    this.isLogin = false;
  }

  asignarFavoritos() {

    const data = localStorage.getItem(`favorites_${this.authenticationService.getUserId()}`);
    this.favorites = data !== undefined && data !== null ? JSON.parse(data) : [];

    if (this.favorites.length > 0) {



      let favorito: LRecursosExt = new LRecursosExt();
      favorito.descripcion = "Favoritos";
      favorito.nombre = "Favoritos";
      favorito.caracteristicas = [];
      favorito.claseGraficaCssRecursoMenu = "fa-solid fa-star";
      favorito.idRecurso = -1;

      this.favorites.forEach(element => {
        let caract = new LCaracteristicas();
        caract.claseGraficaCssCaracteristica = element.icono;
        caract.nombre = element.nombre;
        caract.funcionCaracteristica = element.link;
        caract.idCaracteristica = -1;
        caract.caracteristicas = null;
        caract.favorito = true;
        caract.fkRecurso = -1;
        favorito.caracteristicas.push(caract)
      });

      this.menu.push(favorito);
    }

  }

  goToFavorito(item: LCaracteristicas) {

    if (item.funcionCaracteristica) {
      this.router.navigate([item.funcionCaracteristica]);

    }

    //if (!this.media.isActive('gt-sm')) {
    //this.sidenav.close();
    //}


  }
  viewFavorito(event, evitem: LCaracteristicas) {
    const index = this.favorites.findIndex(s => s.link === evitem.funcionCaracteristica);
    this.FavoritoSelect = this.favorites[index];
    event.preventDefault();
  }

  overMenu(dataItem: LRecursosExt) {
    this.menuOver = dataItem;
  }
  public getContextDataMenu = (anchor: Element) => {

    return this.menuOver;
  }



  public getContextData = (anchor: Element) => {

    return this.FavoritoSelect;
  }

  rename(item) {
    var ren = prompt("Renombrar favorito", item.nombre);
    if (ren) {
      try {

        const index = this.favorites.findIndex(s => s.link === item.link);
        this.favorites[index].nombre = ren
        localStorage.setItem(`favorites_${this.authenticationService.getUserId()}`, JSON.stringify(this.favorites));

        this.menu[0].caracteristicas.filter(s => s.funcionCaracteristica === item.link)[0].nombre = ren;

        //this.presentToast(`Se renombró tus favoritos a ${item.nombre}`);

        //this.close()
        //this.loadFavorites();

      } catch (error) {
        //console.log("removeFavorite", error)
      }

    }
  }

  removeFavorite(item) {
    try {

      const index = this.favorites.findIndex(s => s.link === item.link);

      this.favorites.splice(index, 1);
      localStorage.setItem(`favorites_${this.authenticationService.getUserId()}`, JSON.stringify(this.favorites));
      //this.presentToast(`Se removió ${item.nombre} de tus favoritos`);

      this.menu[0].caracteristicas = this.menu[0].caracteristicas.filter(s => s.funcionCaracteristica !== item.link);




    } catch (error) {
      //console.log("removeFavorite", error)
    }


  }


  UpdateVersion() {
    const currentVersion = this.persistenceService.getData(
      LocalStorageList.version,
      true
    );
    this.ValidateVersion(this.version, currentVersion);
  }
  ValidateVersion(currenVersion: string, newVersion: string) {
    let v1 = currenVersion.split(".");
    let v2 = newVersion.split(".");
    if (v1[0] !== v2[0] || v1[1] !== v2[1]) this.logout();
    else location.reload();
  }


  async serverStatus() {
    const response = await this.authenticationService.status();
  }
  checkSession() {
    const ti = timer(2000, 1000);
    this.myTimerSub = ti.subscribe((t) => {
      if (!this.router.url.includes("/login") && !this.router.url.includes("/recovery-password")) {
        if (!this.authenticationService.getToken()) {
          this.authenticationService.logout();
        }
      }
      this.show()
    });

  }

  /**
 *
 * @param message Información a mostrar
 * @param type tipo de mensaje
 */
  presentToast(message: string, type: 'success' | 'warning' | 'error' | 'info' = 'info', alwaysVisible: 'native' | 'yes' | 'no' = "native") {

    let notificationSettings = {} as SPlusNotificationSettings;
    notificationSettings.content = message;
    notificationSettings.type = type;
    notificationSettings.alwaysVisible = alwaysVisible;
    notificationSettings.hideAfter = 3000;

    this.notificationService.Show(notificationSettings);
  }



  OfflineNotify = false;


  show() {

    if (navigator.onLine && this.OfflineNotify) {
      this.OfflineNotify = false;
      this.presentToast("Se restauró la conexión a internet.", "success");
    } else if (!navigator.onLine && !this.OfflineNotify) {
      this.OfflineNotify = true;
      this.presentToast("En estos momentos no hay conexión a internet.", "error", "no");
    }
  }



  private _subscribeNotifications() {

    const notie =  this.sharedService.messageNotification();
    if(notie && notie.message){
      this.ShowAlert(notie.message);
    }
    
  }


  public openedDiag = false;
  description = "";
  isHtml = false;
  title = "";
  public close(status: string): void {
    //console.log(`Dialog result: ${status}`);
    this.openedDiag = false;
  }

  public ShowAlert(args: SPlusDialogSettings): void {
    this.description = args.description;
    this.isHtml = args.isHtml;
    this.title = args.title

    this.openedDiag = true;
  }


}





