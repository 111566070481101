<div class="main m-0 p-0">

  <app-header [drawer]="drawerm" [paymentId]="paymentId"></app-header>

  <kendo-drawer-container class="component-container  main-background sm:min-drawer"
    [ngClass]="{'main-background-login' : isLogin, 'main-background-no-login' : !isLogin, 'search-kendo-drawer-content' : currentModule.at(-1) == 'listado'}">
    <kendo-drawer [hidden]="!showMenu || !isLogin" #drawerm [width]="237" (collapse)="DrawerOnCollapse(false)"
      (expand)="DrawerOnCollapse(true)" [autoCollapse]="false" mode="push" [mini]="true" [(expanded)]="expanded"
      [ngClass]="{'gray-scale' : paymentId == 3}">
      <ng-template kendoDrawerTemplate>


        <kendo-treeview class="treeview-menu" [ngClass]="{'treeview-menu-small': !expanded}" #treeviewmenu
          [nodes]="menu" [filterable]="false" kendoTreeViewExpandable [hidden]="!showMenu" size="none"
          [(expandedKeys)]="expandedKeys" filterInputPlaceholder="Filtrar" [expandOnFilter]="filterExpandSettings"
          kendoTreeViewHierarchyBinding childrenField="caracteristicas" textField="nombre">

          <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">

            <!--Modulos-->
            <div (mouseover)='overMenu(dataItem)' class="">
              <div class="menu-item-module" [ngClass]="{'menu-item-module-min': !expanded}" *ngIf="dataItem.idRecurso"
                (click)="showClick(dataItem, index, internalmyPopoverMenu)" #internalmyPopoverMenu="kendoPopoverAnchor"
                kendoPopoverAnchor [popover]="myPopoverMenu" showOn="hover">

                <i [ngClass]="dataItem.claseGraficaCssRecursoMenu" aria-hidden="true"
                  [class]="'text-' + dataItem.modulo"></i>

                <span [hidden]="!expanded" class="select-none">{{ dataItem.nombre }}</span>

              </div>
            </div>



            <!--item menu link-->
            <a class="menu-item-link background-active text-normal dark:text-white"
              *ngIf="!dataItem.favorito && expanded && !dataItem.idRecurso && !dataItem?.caracteristicas"
              routerLinkActive="background-active" [ngClass]="{'background-active': rla.isActive}"
              #rla="routerLinkActive"
              [routerLink]="dataItem.ruta ? [dataItem.ruta] : [dataItem.controladorCaracteristica] + '/' + dataItem.funcionCaracteristica">


              <i [class]="'text-' + dataItem.modulo" class="select-none menu-item-node-inside"
                [ngClass]="dataItem.claseGraficaCssCaracteristica"></i>

              <div [hidden]="!expanded" class="select-none">
                {{ dataItem.nombre }}
              </div>

            </a>

            <div *ngIf="dataItem.favorito && expanded && !dataItem.idRecurso && !dataItem?.caracteristicas"
              class="flex flex-row justify-between items-center">
              <div class="menu-item-link background-active flex-90" routerLinkActive="background-active"
                [class]="'text-' + dataItem.modulo" (click)="goToFavorito(dataItem)"
                [ngClass]="{'background-active': rla.isActive}" #rla="routerLinkActive">


                <i [class]="'text-' + dataItem.modulo" class="select-none menu-item-node-inside"
                  [ngClass]="dataItem.claseGraficaCssCaracteristica"></i>

                <div [hidden]="!expanded" class="select-none">
                  {{ dataItem.nombre }}
                </div>
              </div>

              <button class="flex-10 mr-10" kendoButton kendoPopoverAnchor [popover]="myPopover"
                (click)="viewFavorito($event, dataItem)" fillMode="clear">
                <i class="fa-solid fa-ellipsis-vertical" style="color: #607d8b;"></i>

              </button>

            </div>


            <!--caracteristicas con sub menu-->
            <div class="menu-item-submenu" *ngIf="expanded && !dataItem.idRecurso && dataItem?.caracteristicas"
              (click)="showClick(dataItem, index, this)">

              <i ngClass="fa-solid fa-file-contract" aria-hidden="true" [class]="'text-' + dataItem.modulo"></i>

              <span class="select-none">{{ dataItem.nombre }}</span>



            </div>



          </ng-template>



        </kendo-treeview>


        <!--menu contextual-->
        <kendo-popover [offset]="0" position="right" class="popover-menu-contextual" [callout]="false" #myPopoverMenu
          [templateData]="getContextDataMenu">
          <ng-template kendoPopoverTitleTemplate let-anchor let-dataItem="data" *ngIf="!expanded">

            <div class="menu-item-module">

              <span [class]="'text-' + dataItem.modulo" class="select-none "><b>{{
                  dataItem?.nombre }}</b></span>
            </div>

          </ng-template>

          <ng-template kendoPopoverBodyTemplate let-anchor="internalmyPopoverMenu" let-databody="data"
            *ngIf="!expanded">


            <kendo-treeview class="treeview-menu-contextual" size='small' #treeviewmenucontext
              [nodes]="databody.caracteristicas" [filterable]="false" kendoTreeViewExpandable size="none"
              [(expandedKeys)]="expandedKeys" filterInputPlaceholder="Filtrar" [expandOnFilter]="filterExpandSettings"
              kendoTreeViewHierarchyBinding childrenField="caracteristicas" textField="nombre">

              <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
                <!--item menu link-->
                <a class="menu-item-link background-active  text-normal dark:text-white"
                  *ngIf="!dataItem.idRecurso && !dataItem?.caracteristicas" routerLinkActive="background-active"
                  [ngClass]="{'background-active': rla.isActive}" #rla="routerLinkActive" (click)="OcultarMenu()"
                  [routerLink]="dataItem.ruta ? [dataItem.ruta] : [dataItem.controladorCaracteristica] + '/' + dataItem.funcionCaracteristica">

                  <i [class]="'icon-color' " class="select-none menu-item-node-inside"
                    [class]="'text-' + dataItem.modulo" [ngClass]="dataItem.claseGraficaCssCaracteristica"></i>

                  <span class="select-none nav-link">
                    {{ dataItem.nombre }}
                  </span>
                </a>


                <!--caracteristicas con sub menu-->
                <div class="menu-item-submenu" *ngIf="!dataItem.idRecurso && dataItem?.caracteristicas"
                  (click)="showClick(dataItem, index, anchor)">

                  <i ngClass="fa-solid fa-file-contract" aria-hidden="true" [class]="'text-' + dataItem.modulo"></i>

                  <span class="select-none">{{ dataItem.nombre }}</span>



                </div>


              </ng-template>

            </kendo-treeview>

          </ng-template>

        </kendo-popover>

      </ng-template>


    </kendo-drawer>
    <kendo-drawer-content class="" [ngClass]="{'header-abs': windowScrolled, 'header-payment': paymentId == 1}">
      <div class="inner-sidenav-conten space-initial ">
        <div class="content-show">
          <splus-forms-process-message *ngIf="showMessageProcess" [type]="messageProcess.type"
            [title]="messageProcess.title" [message]="messageProcess.message"></splus-forms-process-message>

          <router-outlet></router-outlet>



        </div>
      </div>

    </kendo-drawer-content>
  </kendo-drawer-container>


  <kendo-popover #myPopover [width]="300" [templateData]="getContextData" [callout]="false">
    <ng-template kendoPopoverTitleTemplate let-data="data">
      <div class="flex flex-row items-start justify-center">
        <div class="flex-20" style="text-align: center;">

          <i [class]="data.icono" [class]="'text-' + (data.padre | lowercase)" class="uix-icon set-color"></i>
        </div>
        <div class="flex-70">
          <div><b>{{data.nombre}}</b></div>
          <div>{{data.padre}}</div>
        </div>

      </div>

    </ng-template>

    <ng-template kendoPopoverBodyTemplate let-anchor let-data="data">

      <section class="user-menu">

        <div class="splus-padding-left-10">
          <a class="text-link" [href]="data.link">

            <i class="fa-solid fa-link"></i>

            {{data.link}}
          </a>

        </div>

      </section>


    </ng-template>

    <ng-template kendoPopoverActionsTemplate class let-data="data" class="popover-favorito-action">


      <button (click)="rename(data)" kendoButton fillMode="flat" themeColor="primary">
        <i class="fa-solid fa-wand-magic-sparkles"></i>
        Renombrar
      </button>

      <button (click)="removeFavorite(data)" kendoButton fillMode="flat" themeColor="primary">
        <i class="fa-regular fa-trash-can" style="color:#c95242"></i>
        Eliminar favorito
      </button>

    </ng-template>
  </kendo-popover>




</div>
<kendo-dialog [themeColor]="'primary'" [title]="title" *ngIf="openedDiag" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <div>
    <div *ngIf="!isHtml">{{description}}</div>
    <div *ngIf="isHtml" [innerHtml]="description"></div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('no')">Cerrar</button>

  </kendo-dialog-actions>
</kendo-dialog>