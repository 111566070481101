<nav class="bg-primary  dark:bg-slate-700" [ngClass]="{'gray-scale' : paymentId == 3}" *ngIf="isLogin">
  <!--max-h-[43px]-->
  <div class=""> <!--mx-auto max-w-7xl px-2 sm:px-6 lg:px-8-->
    <div class="relative flex h-[43px] items-center justify-between max-h-[43px]">

      <div class="flex flex-1   sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center">
          <button kendoButton fillMode="flat" (click)="drawer.toggle()">
            <i class="fa-solid fa-bars text-white"></i>
          </button>

        </div>
        <div class="w-52 min-w-[208px]">
          <a href="/">
            <img src="assets/images/logosaludplusblanco.png" width="140px" class="ml-2 inline-block align-middle"></a>
        </div>
        <div class="border-r border-gray-200 opacity-30 mr-1 max-h-[18px]  mt-1"></div>
        <div class=" ">
          <div class="flex space-x-4 mt-1">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <div class="text-sm text-white line-clamp-1 ">{{nombreInstitucion}}</div>

            <div class="flex flex-row justify-center items-center" *ngIf="sandbox == 2">
              <i class="fa-solid fa-flask text-inventario"></i>
              <div class="ml-2 font-bold text-white text-sm">Pruebas</div>
            </div>

            <div class="flex flex-row justify-center items-center" *ngIf="sandbox == 1">
              <i class="fa-sharp fa-solid fa-bolt text-auditoria"></i>
              <div class="ml-2 font-bold text-white text-sm">Pre Producción</div>
            </div>


          </div>
        </div>
      </div>


      <div class="  inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">

        <!-- dark -->
        <div class="hidden xl:block md:block sm:tw-hidden mr-4">
          <kendo-badge-container>

            <button kendoButton (click)="onChangeSwitch()" fillMode="flat" class="theme-swich">
              <i *ngIf="themeSwitcher" class="fa-thin fa-sun-bright  fa-lg  text-white"></i>
              <i *ngIf="!themeSwitcher" class="fa-thin fa-moon-stars fa-lg  text-white"></i>
            </button>

          </kendo-badge-container>
        </div>


        <!-- notificaccion -->
        <div class="hidden xl:block md:block sm:hidden xs:hidden mr-4">
          <kendo-badge-container>
            <i class="fa-thin fa-bell text-lg text-white"></i>
          </kendo-badge-container>
        </div>


        <!-- ayuda -->
        <div class="hidden xl:block md:block sm:hidden mr-4">
          <button kendoButton [popover]="popoverHelp" fillMode="flat"
            (click)="popoverHelpIsShow ?  onEvent($event,anchorHelp) :  anchorHelp.toggle()" kendoPopoverAnchor
            showOn="click" #anchorHelp="kendoPopoverAnchor">
            <i class="fa-thin fa-circle-question fa-lg text-white"></i>
          </button>
        </div>

        <!-- mas  --> 
        <div class="xl:hidden md:hidden sm:block mr-4">
          <button kendoButton fillMode="flat" (click)="opened = true" showOn="click">
            <i class="fa-duotone fa-gear fa-lg text-white"></i>
          </button>
        </div>



        <div
          class="border-r border-gray-200 opacity-30 mr-1 max-h-[18px] w-[10px] pr-1 hidden xl:block md:block sm:hidden xs:hidden">
          &nbsp;</div>

        <!-- Profile dropdown -->
        <div class="relative ml-3 hidden xl:block md:block sm:hidden xs:hidden ">
          <div>
            <button kendoButton [popover]="popoverUser" fillMode="flat" class="p-0"
              (click)="popoverUserIsShow ?  onEvent($event,anchorUser) :  anchorUser.toggle()" kendoPopoverAnchor
              showOn="click" #anchorUser="kendoPopoverAnchor">

              <div class="flex flex-row items-center justify-center mr-3">
                <splus-avatar [initials]="iniciales" width="32px" height="32px" class="ml-2"></splus-avatar>

                <div class="mr-4">
                  <div class="text-sm text-white">{{name | titlecase}}</div>
                  <div class="text-sm text-white">{{(perfiles ? perfiles[0] : '') | titlecase}}</div>
                </div>

                <i [ngClass]="{'fa-angle-down': !popoverUserIsShow , 'fa-angle-up': popoverUserIsShow}"
                  class="fa-solid fa-angle-down text-xs text-white"></i>
              </div>

            </button>
          </div>


        </div>
      </div>
    </div>
  </div>




</nav>



<kendo-popover #popoverUser width="350px" position="bottom" (shown)="popoverUserIsShow = true"
  (hidden)="popoverUserIsShow = false" [animation]="{type: 'slide', direction : 'down', duration: 200 }" [callout]="false">
  <ng-template kendoPopoverTitleTemplate>

    <div class="profile-bg ">
      <div>
        <div class="flex flex-row justify-around items-center p-2">
          <div class="k-flex-30 ">
            <splus-avatar [initials]="iniciales" width="50px" height="50px"></splus-avatar>
          </div>
          <div class="k-flex-50">
            <div>
              <div>{{name | titlecase}}</div>
              <div>{{(perfiles ? perfiles[0] : '') | titlecase}}</div>
            </div>
          </div>
          <div class="k-flex-20">
            <button kendoButton [rounded]="'full'" [fillMode]="'solid'" (click)="logout()">
              <a kendoCardTitle class="text-link dark:text-white">Cerrar sesión</a>
            </button>
          </div>
        </div>

        <div class="border-t border-gray-300 py-1">
          <div class="text-gray-700 dark:text-white font-bold px-4">Perfiles</div>
          <div *ngFor="let item of perfiles">
            <i class="fa-solid fa-award text-teal-500"></i>
            {{item | titlecase}}
          </div>
        </div>
      </div>

    </div>
  </ng-template>
</kendo-popover>



<!-- popover help -->
<kendo-popover #popoverHelp width="350px" position="bottom" (shown)="popoverHelpIsShow = true"
  (hidden)="popoverHelpIsShow = false" [animation]="{type: 'slide', direction : 'down', duration: 200 }" [callout]="false">
  <ng-template kendoPopoverTitleTemplate>
    <div class="p-1">
      <div>
        <div class="text-gray-700 dark:text-white font-bold px-4 py-4 border-b border-gray-300 mb-4">
          Ayuda</div>

        <div class="flex flex-row justify-between items-center mb-4">
          <button *ngFor="let dataItem of toolbarAyudar" kendoButton (click)="OpenDocumentacion()" width="100%">
            <i [class]="dataItem.icon" class="custom-popup-item-icon"></i> {{dataItem.text | titlecase}}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</kendo-popover>

<!-- dialog movil -->
<kendo-dialog title="Opciones" *ngIf="opened" maxWidth="95%" [minWidth]="250" maxHeight="95%" (close)="close('cancel')">

  <!--<div class="columns flex-row justify-between items-center mb-4">
    <button *ngFor="let dataItem of toolbarAyudar" kendoButton (click)="OpenDocumentacion()" width="100%">
      <i [class]="dataItem.icon" class="custom-popup-item-icon"></i> {{dataItem.text | titlecase}}
    </button>
  </div> -->
  <div class="flex flex-row justify-between items-center">
    <div>
      <h1 class="text-3xl font-medium">{{name | titlecase}}</h1>
    </div>
    <div class="inline-flex space-x-2 items-center">

      <button class="ml-4" kendoButton [rounded]="'full'" [fillMode]="'solid'" (click)="logout()">
        <a kendoCardTitle class="text-link dark:text-white">Cerrar sesión</a>
      </button>
    </div>
  </div>
  <p class="text-slate-500">{{(perfiles ? perfiles[0] : '') | titlecase}}</p>

  <div id="tasks" class="my-5">


    <div *ngFor="let dataItem of toolbarAyudar" (click)="OpenDocumentacion()"
      class="flex justify-between items-center border-b border-slate-200 py-3 px-2 border-l-4  border-l-transparent   hover:from-slate-100 transition ease-linear duration-150">
      <div class="inline-flex items-center space-x-2">
        <div>
          <i [class]="dataItem.icon" class="custom-popup-item-icon"></i>

        </div>
        <div>{{dataItem.text | titlecase}}</div>
      </div>
    </div>

    <div (click)="onChangeSwitch()"
      class="flex justify-between items-center border-b border-slate-200 py-3 px-2 border-l-4  border-l-transparent   hover:from-slate-100 transition ease-linear duration-150">
      <div class="inline-flex items-center space-x-2">
        <div>
          <i *ngIf="themeSwitcher" class="fa-thin fa-sun-bright  fa-lg "></i>
          <i *ngIf="!themeSwitcher" class="fa-thin fa-moon-stars fa-lg  "></i>

        </div>
        <div>Tema</div>
      </div>

    </div>
  </div>

  <div>

  </div>


</kendo-dialog>



 