import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ComponentsCoreFormsModule } from '@src/app/components/Core/core.saludplus.module';
import { DialogModule } from '@progress/kendo-angular-dialog';


@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,  TooltipsModule,IndicatorsModule, NavigationModule,    
    ComponentsCoreFormsModule , DialogModule
  ],
  exports: [HeaderComponent],
  declarations: [HeaderComponent]
})
export class HeaderModule { }
