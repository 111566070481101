import { Routes, RouterModule } from "@angular/router";
import { TercerosRoutingModule } from "../Contabilidad/terceros/terceros.routing";
import { CertificadoDisponibilidadPresupuestalRoutingModule } from "./certificado-disponibilidad-presupuestal/certificado-disponibilidad-presupuestal.routing";
import { CertificadoRegistroPresupuestalRoutingModule } from "./certificado-registro-presupuestal/certificado-registro-presupuestal.routing";
import { IngresoPagoRoutingModule } from "./ingreso-pago/ingreso-pago.routing";
import { LiberacionPresupuestalRoutingModule } from "./liberacion-presupuestal/liberacion-presupuestal.routing";
import { MovimientosRoutingModule } from "./movimientos/movimientos-routing.module";
import { OrdenPagoRoutingModule } from "./orden-pago/orden-pago.routing";
import { EjecucionPresupuestalRoutingModule } from "./reportes/ejecucion-presupuestal/ejecucion-presupuesta.routing";
import { ListadoAuxiliaresPresupuestoRoutingModule } from "./reportes/listado-auxiliares-presupuesto/listado-auxiliares-presupuesto.routing";
import { ListadoAuxiliaresTipoDocumentoRoutingModule } from "./reportes/listado-auxiliares-tipo-documento/listado-auxiliares-presupuesto.routing";
import { RubrosPresupuestalesRoutingModule } from "./rubros-presupuestales/rubros-presupuestales.routing";
import { VigenciasRoutingModule } from "./vigencias/vigencias-routing.module";

const routes: Routes = [
  //Vigencias
  VigenciasRoutingModule.RoutingVigencias,

  //Rubros presupuestales
  RubrosPresupuestalesRoutingModule.RoutingRubrosPresupuestalesRedirect,
  RubrosPresupuestalesRoutingModule.RoutingRubrosPresupuestales,

  //Movmiviento presupuestal
  MovimientosRoutingModule.RoutingMovimientoPresupuestalRedirect,
  MovimientosRoutingModule.RoutingMovimientoPresupuestal,

  // Certificado Registro Presupuestal
  CertificadoRegistroPresupuestalRoutingModule.RoutingCertificadoRegistroPresupuestalRedirect,
  CertificadoRegistroPresupuestalRoutingModule.RoutingCertificadoRegistroPresupuestal,

  //Certificado Disponibilidad Presupuestal
  CertificadoDisponibilidadPresupuestalRoutingModule.RoutingCertificadoDisponibilidadPresupuestalRedirect,
  CertificadoDisponibilidadPresupuestalRoutingModule.RoutingCertificadoDisponibilidadPresupuestal,

  // Certificado Registro Presupuestal
  CertificadoRegistroPresupuestalRoutingModule.RoutingCertificadoRegistroPresupuestalRedirect,
  CertificadoRegistroPresupuestalRoutingModule.RoutingCertificadoRegistroPresupuestal,

  // Orden de pago
  OrdenPagoRoutingModule.RoutingOrdenPagoRedirect,
  OrdenPagoRoutingModule.RoutingOrdenPago,

  // Ingreso de pago
  IngresoPagoRoutingModule.RoutingIngresoPagoRedirect,
  IngresoPagoRoutingModule.RoutingIngresoPago,
  // Liberacion presupuestal
  LiberacionPresupuestalRoutingModule.RoutingLiberacionPresupuestal,

  // Listado Auxiliares Presupuesto
  ListadoAuxiliaresPresupuestoRoutingModule.RoutingListadoAuxiliaresPresupuesto,
  ListadoAuxiliaresTipoDocumentoRoutingModule.RoutingListadoAuxiliaresTipoDocumento,
  
  // Ejecucion presupuestal
  EjecucionPresupuestalRoutingModule.RoutingEjecucionPresupuestal,

  //Terceros
  TercerosRoutingModule.RoutingTercerosRedirectPresupuesto,
  TercerosRoutingModule.RoutingTercerosPresupuesto,
];
export const PresupuestoRoutes = RouterModule.forChild(routes);
